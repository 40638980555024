export type EnvironmentType = 'development' | 'staging' | 'production';

interface EnvironmentManifestIface {
  API_ENV: EnvironmentType;
  UI_ENV: EnvironmentType;
}

const API_ENV = (process.env.API_ENV ?? 'production') as EnvironmentType;
const UI_ENV = (process.env.UI_ENV ?? 'production') as EnvironmentType;

export const EnvironmentManifest: EnvironmentManifestIface = {
  // The environment type of the API web server
  API_ENV,
  // The environment type of the mobile app
  UI_ENV,
};
