import { dpFromPx as dpFromPxLib } from './lib/dpFromPx';

export const dp = dpFromPxLib;
export const dpFromPx = dpFromPxLib;
export const Unit = dpFromPx(6);

export const Breakpoint = {
  Small: dpFromPx(640),
  Medium: dpFromPx(1000),
};
