import * as Constants from 'src/constants';
import * as React from 'react';
import { Text as NativeText, TextProps } from 'react-native';

interface RefObject<T> {
  readonly current: T | null;
}
// Bivariance hack for consistent unsoundness with RefObject
type RefCallback<T> = { bivarianceHack(instance: T | null): void }['bivarianceHack'];
type Ref<T> = RefCallback<T> | RefObject<T> | null;

const Text = React.forwardRef<NativeText, TextProps>((props, ref) => (
  <NativeText
    {...props}
    ref={ref}
    style={[
      /**
       * This color default is to support dark mode
       */
      Constants.TextStyle.CMidnight,
      props.style,
    ]}
  />
));

export default Text;
