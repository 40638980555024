import * as Constants from 'src/constants';
import BigButton from 'src/components/BigButton';
import Text from 'src/components/Text';
import Image from 'next/image';
import Link from 'next/link';
import { StyleSheet, View } from 'react-native';

const Home: React.FC<{}> = () => {
  return (
    <View style={styles.root}>
      <View style={styles.descriptionMidColumn}>
        <View style={styles.edgeGutter}>
          <Text style={[Constants.TextStyle.T42B, Constants.TextStyle.ACenter, Constants.GridStyle.MB2Unit]}>
            {'Stay motivated'}
          </Text>
          <Text
            style={[
              Constants.TextStyle.T24R,
              Constants.TextStyle.CDarkGray,
              Constants.TextStyle.ACenter,
              Constants.GridStyle.MB8Unit,
            ]}
          >
            {
              'Check a unified graph of your sales, set monthly goals, and see how you stack up against other sellers using OneShop.'
            }
          </Text>
          <View style={styles.actionRow}>
            <Link href='/signup'>
              <a
                style={{
                  textDecoration: 'none',
                }}
              >
                <BigButton type='primary'>{'Try 7 days free'}</BigButton>
              </a>
            </Link>
          </View>
        </View>
        <View
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLJCC,
            Constants.GridStyle.MT8Unit,
            Constants.GridStyle.MB8Unit,
          ]}
        >
          <View>
            <View style={styles.revenueGraphCard}>
              <Image
                src='/static/images/home/models/revenue-graph.jpg?v=1'
                width={1170}
                height={1027}
                alt='OneShop app rendering'
              />
            </View>
            <div className='phoneGraphic'>
              <div className='phoneGraphicContent'>
                <Image
                  src='/static/images/home/models/home.jpg?v=2'
                  width={1170}
                  height={2532}
                  alt='OneShop app rendering'
                />
              </div>
            </div>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    paddingTop: 4 * Constants.Grid.dp(36),
  },
  midColumn: {
    width: '100%',
    maxWidth: Constants.Grid.dp(860),
  },
  descriptionMidColumn: {
    width: '100%',
    maxWidth: Constants.Grid.dp(640),
  },
  edgeGutter: {
    paddingHorizontal: Constants.Grid.dp(12),
  },
  heroImage: {
    width: '100%',
  },
  featurePreview: {
    padding: Constants.Grid.dp(24),
    borderRadius: Constants.Grid.dp(6),
    backgroundColor: Constants.NewColor.White,
    zIndex: 1,
  },
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  revenueGraphCard: {
    position: 'absolute',
    top: Constants.Grid.dp(120),
    left: 0,
    width: '100%',
    maxWidth: Constants.Grid.dp(360),
    borderRadius: Constants.Grid.dp(24),
    elevation: 3,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 20,
    shadowOpacity: 0.1,
    zIndex: 1,
    transform: [
      {
        scale: 1.1,
      },
    ],
    overflow: 'hidden',
  },
});

export default Home;
