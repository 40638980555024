import * as Constants from 'src/constants';
import Image from 'next/image';
import { StyleSheet, View } from 'react-native';

const SupportedSites: React.FC<{}> = () => {
  return (
    <View style={styles.root}>
      <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.MB4Unit]}>
        <View style={[styles.image, styles.poshmarkSiteIcon]}>
          <Image
            src='/static/images/home/sites/Poshmark@3x.png?v=1'
            width={72}
            height={72}
            alt='Poshmark Logo'
            loading='eager'
          />
        </View>
        <View style={[styles.image, styles.mercariSiteIcon]}>
          <Image
            src='/static/images/home/sites/Mercari@3x.png?v=1'
            width={72}
            height={72}
            alt='Mercari Logo'
            loading='eager'
          />
        </View>
        <View style={[styles.image, styles.depopSiteIcon]}>
          <Image
            src='/static/images/home/sites/Depop@3x.png?v=1'
            width={72}
            height={72}
            alt='Depop Logo'
            loading='eager'
          />
        </View>
      </View>
      <View style={Constants.GridStyle.FLDR}>
        <View style={[styles.image, styles.ebaySiteIcon]}>
          <Image
            src='/static/images/home/sites/eBay@3x.png?v=1'
            width={72}
            height={72}
            alt='eBay Logo'
            loading='eager'
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    paddingVertical: 4 * Constants.Grid.dp(12),
  },
  image: {
    width: Constants.Grid.dp(48),
    height: Constants.Grid.dp(48),
    marginHorizontal: Constants.Grid.dp(12),
    padding: Constants.Grid.dp(6),
    borderRadius: Constants.Grid.dp(18),
  },
  poshmarkSiteIcon: {
    backgroundColor: 'rgba(130, 36, 50, 1)',
  },
  mercariSiteIcon: {
    backgroundColor: 'rgba(83, 98, 240, 1)',
  },
  tradesySiteIcon: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
  },
  depopSiteIcon: {
    backgroundColor: 'rgba(255, 36, 4, 1)',
  },
  ebaySiteIcon: {
    backgroundColor: '#FFFFFF',
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: Constants.NewColor.OnWhiteBorderGray,
  },
  grailedSiteIcon: {
    backgroundColor: '#FFFFFF',
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: Constants.NewColor.OnWhiteBorderGray,
  },
});

export default SupportedSites;
