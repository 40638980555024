import * as Grid from './Grid';
import { StyleSheet } from 'react-native';

export const GridStyle = StyleSheet.create({
  MUnit: {
    margin: Grid.Unit,
  },
  M2Unit: {
    margin: 2 * Grid.Unit,
  },
  MVUnit: {
    marginVertical: Grid.Unit,
  },
  MV2Unit: {
    marginVertical: 2 * Grid.Unit,
  },
  MV4Unit: {
    marginVertical: 4 * Grid.Unit,
  },
  MHUnit: {
    marginHorizontal: Grid.Unit,
  },
  MH2Unit: {
    marginHorizontal: 2 * Grid.Unit,
  },
  MH4Unit: {
    marginHorizontal: 4 * Grid.Unit,
  },
  MTLine: {
    marginTop: 1,
  },
  MVHUnit: {
    marginVertical: 0.5 * Grid.Unit,
  },
  MTHUnit: {
    marginTop: 0.5 * Grid.Unit,
  },
  MTUnit: {
    marginTop: Grid.Unit,
  },
  MT2Unit: {
    marginTop: 2 * Grid.Unit,
  },
  MT4Unit: {
    marginTop: 4 * Grid.Unit,
  },
  MT8Unit: {
    marginTop: 8 * Grid.Unit,
  },
  MBUnit: {
    marginBottom: Grid.Unit,
  },
  MBHUnit: {
    marginBottom: 0.5 * Grid.Unit,
  },
  MB2Unit: {
    marginBottom: 2 * Grid.Unit,
  },
  MB4Unit: {
    marginBottom: 4 * Grid.Unit,
  },
  MB8Unit: {
    marginBottom: 8 * Grid.Unit,
  },
  MBLine: {
    marginBottom: 1,
  },
  MRHUnit: {
    marginRight: 0.5 * Grid.Unit,
  },
  MRUnit: {
    marginRight: Grid.Unit,
  },
  MR2Unit: {
    marginRight: 2 * Grid.Unit,
  },
  MR4Unit: {
    marginRight: 4 * Grid.Unit,
  },
  MLUnit: {
    marginLeft: Grid.Unit,
  },
  ML2Unit: {
    marginLeft: 2 * Grid.Unit,
  },
  ML4Unit: {
    marginLeft: 4 * Grid.Unit,
  },
  PUnit: {
    padding: Grid.Unit,
  },
  P2Unit: {
    padding: 2 * Grid.Unit,
  },
  PHUnit: {
    paddingHorizontal: Grid.Unit,
  },
  PH2Unit: {
    paddingHorizontal: 2 * Grid.Unit,
  },
  PVUnit: {
    paddingVertical: Grid.Unit,
  },
  PV2Unit: {
    paddingVertical: 2 * Grid.Unit,
  },
  PTUnit: {
    paddingTop: Grid.Unit,
  },
  PT2Unit: {
    paddingTop: 2 * Grid.Unit,
  },
  PBUnit: {
    paddingBottom: Grid.Unit,
  },
  PB2Unit: {
    paddingBottom: 2 * Grid.Unit,
  },
  PB4Unit: {
    paddingBottom: 4 * Grid.Unit,
  },
  PRUnit: {
    paddingRight: Grid.Unit,
  },
  PR2Unit: {
    paddingRight: 2 * Grid.Unit,
  },
  PL2Unit: {
    paddingLeft: 2 * Grid.Unit,
  },
  FLDR: {
    flexDirection: 'row',
  },
  FLAIC: {
    alignItems: 'center',
  },
  FLAIFE: {
    alignItems: 'flex-end',
  },
  FLJCSA: {
    justifyContent: 'space-around',
  },
  FLJCSB: {
    justifyContent: 'space-between',
  },
  FLJCC: {
    justifyContent: 'center',
  },
  FLJCFE: {
    justifyContent: 'flex-end',
  },
  FLF1: {
    flex: 1,
  },
  FLF2: {
    flex: 2,
  },
  IconStdDim: {
    width: 4 * Grid.Unit,
    height: 4 * Grid.Unit,
  },
  IconSmDim: {
    width: 3 * Grid.Unit,
    height: 3 * Grid.Unit,
  },
});
