import * as React from 'react';
import * as Constants from 'src/constants';
import Button from 'src/components/Button';
import Image from 'next/image';
import Link from 'next/link';
import Text from 'src/components/Text';
import { StyleSheet, View } from 'react-native';

const NavBar: React.FC<{}> = () => {
  return (
    <header
      style={{
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 3,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        backdropFilter: 'blur(5px)',
      }}
    >
      <View style={styles.navRoot}>
        <View style={styles.navContentRoot}>
          <View style={styles.logoImg}>
            <Link href='/'>
              <a>
                <Image src='/static/images/nav-logo.png?v=1' width={294} height={60} priority />
              </a>
            </Link>
          </View>
          <View style={styles.rightColumn}>
            {/*
              <Link href='/login'>
                <a>
                  <Text>
                    {'Log in'}
                  </Text>
                </a>
              </Link>
              */}
            <Link href='/signup'>
              <a>
                <Button type='primary' style={styles.signupButton}>
                  {'Sign up'}
                </Button>
              </a>
            </Link>
            <Link href='/login'>
              <a>
                <Button type='secondary' style={styles.signupButton}>
                  {'Login'}
                </Button>
              </a>
            </Link>
          </View>
        </View>
      </View>
    </header>
  );
};

const styles = StyleSheet.create({
  navRoot: {
    alignItems: 'center',
  },
  navContentRoot: {
    width: '100%',
    height: Constants.Grid.dp(66),
    maxWidth: Constants.Grid.dp(1200),
    paddingHorizontal: Constants.Grid.dp(12),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoImg: {
    width: Constants.Grid.dp(117),
    height: Constants.Grid.dp(24),
  },
  rightColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  signupButton: {
    marginLeft: Constants.Grid.dp(12),
  },
});

export default NavBar;
