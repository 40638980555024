import * as Grid from './Grid';
import { StyleSheet } from 'react-native';
import { NewColor, BrandColor } from '.';

export const ImageStyle = StyleSheet.create({
  PhotoThumbnailPreview: {
    width: 14 * Grid.Unit,
    height: 14 * Grid.Unit,
    borderRadius: 3 * Grid.Unit,
    overflow: 'hidden',
  },
  ListingThumbnailPreview: {
    width: 8 * Grid.Unit,
    height: 8 * Grid.Unit,
    borderRadius: 3 * Grid.Unit,
    overflow: 'hidden',
  },
  LgDim: {
    width: 6 * Grid.Unit,
    height: 6 * Grid.Unit,
  },
  StdDim: {
    width: 4.5 * Grid.Unit,
    height: 4.5 * Grid.Unit,
  },
  SmDim: {
    width: 3 * Grid.Unit,
    height: 3 * Grid.Unit,
  },
  TCWhite: {
    tintColor: NewColor.White,
  },
  TCBolt: {
    tintColor: BrandColor.Bolt,
  },
  TCBlack: {
    tintColor: BrandColor.Midnight,
  },
  TCDarkGray: {
    tintColor: NewColor.DarkGray,
  },
  TCMidnight: {
    tintColor: BrandColor.Midnight,
  },
});
