import * as Constants from 'src/constants';
import Image from 'next/image';
import Text from 'src/components/Text';
import { StyleSheet, View } from 'react-native';

const AvatarUrls = [
  '/static/images/home/trusted-by/1.jpg',
  '/static/images/home/trusted-by/2.jpg',
  '/static/images/home/trusted-by/3.jpg',
  '/static/images/home/trusted-by/4.jpg',
  '/static/images/home/trusted-by/5.jpg',
  '/static/images/home/trusted-by/6.jpg',
  '/static/images/home/trusted-by/7.jpg',
  '/static/images/home/trusted-by/8.jpg',
  '/static/images/home/trusted-by/9.jpg',
  '/static/images/home/trusted-by/10.jpg',
  '/static/images/home/trusted-by/11.jpg',
  '/static/images/home/trusted-by/12.jpg',
  '/static/images/home/trusted-by/13.jpg',
  '/static/images/home/trusted-by/14.jpg',
  '/static/images/home/trusted-by/15.jpg',
  '/static/images/home/trusted-by/16.jpg',
  '/static/images/home/trusted-by/17.jpg',
  '/static/images/home/trusted-by/18.jpg',
  '/static/images/home/trusted-by/19.jpg',
  '/static/images/home/trusted-by/20.jpg',
  '/static/images/home/trusted-by/21.jpg',
  '/static/images/home/trusted-by/22.jpg',
  '/static/images/home/trusted-by/23.jpg',
  '/static/images/home/trusted-by/24.jpg',
];

const Trust: React.FC<{}> = () => {
  return (
    <View style={styles.root}>
      <View style={styles.midColumn}>
        <Text
          style={[
            Constants.TextStyle.T42B,
            Constants.TextStyle.ACenter,
            Constants.GridStyle.MT8Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          {'Trusted by thousands of resellers'}
        </Text>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
          }}
        >
          {AvatarUrls.slice(0, 6 * 4).map((url) => {
            return (
              <View style={styles.avatarGridItem} key={url}>
                <View style={styles.avatarImage}>
                  <Image src={url} width={Constants.Grid.dp(30)} height={Constants.Grid.dp(30)} loading='lazy' />
                </View>
              </View>
            );
          })}
        </div>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    paddingVertical: 4 * Constants.Grid.dp(12),
  },
  midColumn: {
    width: '100%',
    maxWidth: Constants.Grid.dp(640),
  },
  featurePreview: {
    padding: Constants.Grid.dp(24),
    borderRadius: Constants.Grid.dp(6),
    backgroundColor: Constants.NewColor.White,
    zIndex: 1,
  },
  avatarGridItem: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarImage: {
    width: Constants.Grid.dp(30),
    height: Constants.Grid.dp(30),
    borderRadius: Constants.Grid.dp(15),
    overflow: 'hidden',
    marginVertical: Constants.Grid.dp(12),
    opacity: 0.5,
  },
});

export default Trust;
