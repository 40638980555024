export enum Color {
  Primary = '#000000',
  Secondary = '#97A1A9',
  DarkShade = '#E4E8EC',
  LightShade = '#F5F5F5',
  Inverse = '#FFFFFF',
  Positive = '#2A2A50',
  Negative = '#FF7A7A',
  Premium = '#8E4994',
  Affirmative = '#00954D',
  Night = '#383185',
  AccentGreen = '#1EAD69',
  AccentPurple = '#8E4994',
}

export enum NewColor {
  AccentPurple = '#6C34E2',
  AccentRed = '#d90f26',
  AccentGreen = '#00784d',
  AccentOrange = '#d49c35',
  AccentYellow = '#dbba07',
  AccentBlue = '#2364e1',
  BackgroundLightRed = '#ffb3b3',
  White = '#FFF',
  Black = '#000',
  DarkGray = '#7d858c',
  BackgroundHighlightBlue = '#f0f5ff',
  BackgroundGray = '#edeef5',
  OverlayBackgroundGray = 'rgba(187, 190, 194, 0.9)',
  OnWhiteBorderGray = '#d8dce3',
  UnarchivedGray = '#F7F9FF',
}

export enum BrandColor {
  Bolt = '#684CF6',
  Midnight = '#000000',
  MidnightBorder = '#000000',
  BackgroundGray = '#edeef5',
  DarkGray = '#7d858c',
  OnWhiteBorderGray = '#d8dce3',
  FillGray = '#f9f9f9',
  Storm = '#FFA8FF',
  Flash = '#ABF1EE',
  Daylight = '#FFF7ED',
  Dawn = '#FFE8DF',
  White = '#FFFFFF',
  Black = '#000000',
}
