import { BrandColor, Color, NewColor } from './Colors';
import { dpFromPx } from './lib/dpFromPx';
import { StyleSheet } from 'react-native';

export const FONT_STACK =
  ',-apple-system,BlinkMacSystemFont,ui-sans-serif,system-ui,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji';

export const TextStyle = StyleSheet.create({
  T42B: {
    fontFamily: 'Inter_700Bold' + FONT_STACK,
    fontWeight: '700',
    fontSize: dpFromPx(42),
  },
  T32M: {
    fontFamily: 'Inter_500Medium' + FONT_STACK,
    fontWeight: '500',
    fontSize: dpFromPx(32),
  },
  T24B: {
    fontFamily: 'Inter_700Bold' + FONT_STACK,
    fontWeight: '700',
    fontSize: dpFromPx(22),
  },
  T24M: {
    fontFamily: 'Inter_500Medium' + FONT_STACK,
    fontWeight: '500',
    fontSize: dpFromPx(22),
  },
  T24R: {
    fontFamily: 'Inter_400Regular' + FONT_STACK,
    fontWeight: '400',
    fontSize: dpFromPx(22),
  },
  T16B: {
    fontFamily: 'Inter_700Bold' + FONT_STACK,
    fontWeight: '700',
    fontSize: dpFromPx(16),
  },
  T16M: {
    fontFamily: 'Inter_500Medium' + FONT_STACK,
    fontWeight: '500',
    fontSize: dpFromPx(16),
  },
  T16R: {
    fontFamily: 'Inter_400Regular' + FONT_STACK,
    fontWeight: '400',
    fontSize: dpFromPx(16),
  },
  T12M: {
    fontFamily: 'Inter_500Medium' + FONT_STACK,
    fontWeight: '500',
    fontSize: dpFromPx(13),
  },
  T12R: {
    fontFamily: 'Inter_400Regular' + FONT_STACK,
    fontWeight: '400',
    fontSize: dpFromPx(13),
  },
  T12B: {
    fontFamily: 'Inter_700Bold' + FONT_STACK,
    fontWeight: '700',
    fontSize: dpFromPx(13),
  },
  T10B: {
    fontFamily: 'Inter_700Bold' + FONT_STACK,
    fontWeight: '700',
    fontSize: dpFromPx(11),
  },
  T10M: {
    fontFamily: 'Inter_500Medium' + FONT_STACK,
    fontWeight: '500',
    fontSize: dpFromPx(11),
  },
  T10R: {
    fontFamily: 'Inter_400Regular' + FONT_STACK,
    fontWeight: '400',
    fontSize: dpFromPx(11),
  },
  T8M: {
    fontFamily: 'Inter_500Medium',
    fontSize: dpFromPx(10),
  },
  T8R: {
    fontFamily: 'Inter_400Regular',
    fontSize: dpFromPx(10),
  },
  CPrimary: {
    color: Color.Primary,
  },
  CSecondary: {
    color: Color.Secondary,
  },
  CDarkShade: {
    color: Color.DarkShade,
  },
  CLightShade: {
    color: Color.LightShade,
  },
  CBlank: {
    color: Color.Inverse,
  },
  CPositive: {
    color: Color.Positive,
  },
  CNegative: {
    color: Color.Negative,
  },
  CBolt: {
    color: BrandColor.Bolt,
  },
  CMidnight: {
    color: BrandColor.Midnight,
  },
  CAccentPurple: {
    color: NewColor.AccentPurple,
  },
  CAccentGreen: {
    color: NewColor.AccentGreen,
  },
  CAccentRed: {
    color: NewColor.AccentRed,
  },
  CAccentOrange: {
    color: NewColor.AccentOrange,
  },
  CAccentYellow: {
    color: NewColor.AccentYellow,
  },
  CWhite: {
    color: NewColor.White,
  },
  CDarkGray: {
    color: NewColor.DarkGray,
  },
  CBackgroundGray: {
    color: BrandColor.MidnightBorder,
  },
  CBlack: {
    color: BrandColor.Midnight,
  },
  ACenter: {
    textAlign: 'center',
  },
  ARight: {
    textAlign: 'right',
  },
  TDLUnderline: {
    textDecorationLine: 'underline',
  },
});
