import * as React from 'react';
import NavBar from 'src/views/Marketing/components/NavBar';
import Hero from './Hero';
import Home from './Home';
import SupportedSites from './SupportedSites';
import Bots from './Bots';
import Trust from './Trust';
import Listing from './Listing';
import SimpleListingForm from './SimpleListingForm';
import Community from './Community';
import Footer from 'src/views/Marketing/components/Footer';
import { ScrollView, StyleSheet } from 'react-native';

const Screen: React.FC<{}> = () => {
  return (
    <>
      <ScrollView style={styles.scrollView}>
        <NavBar />
        <Hero />
        <SupportedSites />
        <Trust />
        <Home />
        <Listing />
        <SimpleListingForm />
        <Bots />
        <Community />
        <Footer />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
});

export default Screen;
