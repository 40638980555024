import * as React from 'react';
import * as Constants from 'src/constants';
import { dpFromPx } from 'src/constants/lib/dpFromPx';
import Text from 'src/components/Text';
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface NewPropsIface {
  type: 'primary' | 'secondary' | 'danger' | 'link';
  asCircle?: boolean;
  style?: StyleProp<ViewStyle>;
  leftAdornment?: React.ReactNode;
  rightAdornment?: React.ReactNode;
  textStyle?: StyleProp<TextStyle>;
}

const HIT_SLOP = {
  top: 12,
  bottom: 12,
  left: 0,
  right: 0,
};

export type PropsIface = NewPropsIface & TouchableOpacityProps;

const Button: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const { type, children, ...touchableOpacityProps } = props;
  const disabled: boolean = Boolean(props.disabled);
  const style: StyleProp<ViewStyle> = React.useMemo(() => {
    return [
      type !== 'link' && buttonStyles.paddedRoot,
      buttonStyles[type],
      disabled && buttonStyles.disabled,
      touchableOpacityProps.style,
      props.asCircle && buttonStyles.circular,
      props.style,
    ];
  }, [type, disabled, props.style]);
  const textEl = <Text style={[Constants.TextStyle.T12M, buttonTextStyles[type], props.textStyle]}>{children}</Text>;
  if (props.onPress != null) {
    return (
      <TouchableOpacity disabled={disabled} hitSlop={HIT_SLOP} {...touchableOpacityProps} style={style}>
        {props.leftAdornment}
        {textEl}
        {props.rightAdornment}
      </TouchableOpacity>
    );
  } else {
    return (
      <View style={style}>
        {props.leftAdornment}
        {textEl}
        {props.rightAdornment}
      </View>
    );
  }
};

const buttonStyles = StyleSheet.create({
  paddedRoot: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 2 * Constants.Grid.Unit,
    height: 6 * Constants.Grid.Unit,
    borderRadius: 3 * Constants.Grid.Unit,
  },
  primary: {
    backgroundColor: Constants.BrandColor.Bolt,
  },
  secondary: {
    backgroundColor: Constants.BrandColor.BackgroundGray,
  },
  danger: {
    backgroundColor: Constants.BrandColor.BackgroundGray,
  },
  disabled: {
    opacity: 0.5,
  },
  circular: {
    paddingHorizontal: 0,
    width: 6 * Constants.Grid.Unit,
  },
  link: {},
});

const buttonTextStyles = {
  primary: Constants.TextStyle.CWhite,
  secondary: Constants.TextStyle.CMidnight,
  danger: Constants.TextStyle.CAccentRed,
  link: Constants.TextStyle.CPositive,
};

export default Button;
