import * as Constants from 'src/constants';
import BigButton from 'src/components/BigButton';
import Text from 'src/components/Text';
import Image from 'next/image';
import Link from 'next/link';
import { StyleSheet, View } from 'react-native';

const Home: React.FC<{}> = () => {
  return (
    <View style={styles.root}>
      <View style={styles.descriptionMidColumn}>
        <View style={styles.edgeGutter}>
          <Text style={[Constants.TextStyle.T42B, Constants.TextStyle.ACenter, Constants.GridStyle.MB4Unit]}>
            {'How resellers go pro'}
          </Text>
          <Text
            style={[
              Constants.TextStyle.T24R,
              Constants.TextStyle.CDarkGray,
              Constants.TextStyle.ACenter,
              Constants.GridStyle.MB4Unit,
            ]}
          >
            {
              'Sell on more sites than you could alone. One simple app with every tool you need to level up. Designed for online sellers, just like you.'
            }
          </Text>
          <View style={styles.actionRow}>
            <Link href='/signup'>
              <a
                style={{
                  textDecoration: 'none',
                }}
              >
                <BigButton type='primary'>{'Try 7 days free'}</BigButton>
              </a>
            </Link>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    paddingTop: 2 * Constants.Grid.dp(36),
  },
  midColumn: {
    width: '100%',
    maxWidth: Constants.Grid.dp(860),
  },
  descriptionMidColumn: {
    width: '100%',
    maxWidth: Constants.Grid.dp(640),
  },
  edgeGutter: {
    paddingHorizontal: Constants.Grid.dp(12),
  },
  heroImage: {
    width: '100%',
  },
  featurePreview: {
    padding: Constants.Grid.dp(24),
    borderRadius: Constants.Grid.dp(6),
    backgroundColor: Constants.NewColor.White,
    zIndex: 1,
  },
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default Home;
