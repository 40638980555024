import * as React from 'react';
import * as Constants from 'src/constants';
import { dpFromPx } from 'src/constants/lib/dpFromPx';
import Text from 'src/components/Text';
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface NewPropsIface {
  type: 'primary' | 'secondary' | 'danger' | 'disabled' | 'link';
  style?: StyleProp<ViewStyle>;
  leftAdornment?: React.ReactNode;
  rightAdornment?: React.ReactNode;
  textStyle?: StyleProp<TextStyle>;
}

const HIT_SLOP = {
  top: 12,
  bottom: 12,
  left: 0,
  right: 0,
};

export type PropsIface = NewPropsIface & TouchableOpacityProps;

const Button: React.FC<PropsIface> = (props) => {
  const { type, children, ...touchableOpacityProps } = props;
  const disabled: boolean = type === 'disabled' || Boolean(props.disabled);
  const style: StyleProp<ViewStyle> = React.useMemo(() => {
    return [type !== 'link' && buttonStyles.paddedRoot, buttonStyles[type], touchableOpacityProps.style, props.style];
  }, [type, props.style]);
  const textEl = (
    <Text
      style={[
        Constants.TextStyle.T16B,
        buttonTextStyles[type],
        disabled ? buttonTextStyles.disabled : null,
        props.textStyle,
      ]}
    >
      {children}
    </Text>
  );
  if (props.onPress != null) {
    return (
      <TouchableOpacity disabled={disabled} hitSlop={HIT_SLOP} {...touchableOpacityProps} style={style}>
        {props.leftAdornment}
        {textEl}
        {props.rightAdornment}
      </TouchableOpacity>
    );
  } else {
    return (
      <View style={style}>
        {props.leftAdornment}
        {textEl}
        {props.rightAdornment}
      </View>
    );
  }
};

const buttonStyles = StyleSheet.create({
  paddedRoot: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: dpFromPx(30),
    height: dpFromPx(60),
    borderRadius: dpFromPx(30),
  },
  primary: {
    backgroundColor: Constants.BrandColor.Bolt,
  },
  secondary: {
    backgroundColor: Constants.NewColor.White,
    borderWidth: 1,
    borderColor: Constants.BrandColor.MidnightBorder,
  },
  danger: {
    backgroundColor: Constants.NewColor.White,
    borderColor: Constants.NewColor.AccentRed,
    borderWidth: StyleSheet.hairlineWidth,
  },
  disabled: {
    opacity: 0.5,
    backgroundColor: Constants.NewColor.White,
  },
  link: {},
});

const buttonTextStyles = {
  primary: Constants.TextStyle.CWhite,
  secondary: Constants.TextStyle.CPrimary,
  danger: Constants.TextStyle.CAccentRed,
  disabled: Constants.TextStyle.CSecondary,
  link: Constants.TextStyle.CPositive,
};

export default Button;
