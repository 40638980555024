/**
 * dp measurements are different than the px measurements we use in Figma. A dp is
 * also measured differently across platforms and at times, devices.
 *
 * Below is a transform we can use to centralize this mapping, which will give us the
 * option to make perfect mappings at the platform and device level.
 */
export const dpFromPx = (px: number) => {
  let scaledDp: number = px;

  /**
   * Note: Some devices don't render fractional dp in a predictable matter, so we've
   * chosen to round up fractional dp.
   */
  return Math.ceil(scaledDp);
};
