import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import Image from 'next/image';
import Link from 'next/link';
import { useMediaQuery } from 'react-responsive';
import { StyleSheet, View } from 'react-native';

const Footer: React.FC<{}> = () => {
  const desktopWidth = useMediaQuery({
    minWidth: 860,
  });
  return (
    <View style={styles.root}>
      <View style={[desktopWidth && Constants.GridStyle.FLDR, styles.contentColumn, Constants.GridStyle.MB4Unit]}>
        <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLF1]}>
          <View style={styles.footerColumn}>
            <View style={styles.edgeGutter}>
              <View style={styles.navLogo}>
                <Image src='/static/images/nav-logo.png?v=1' width={294} height={60} />
              </View>
            </View>
          </View>
          <View style={styles.footerColumn}>
            <View style={styles.edgeGutter}>
              <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
                {'PRODUCT'}
              </Text>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='/'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'OneShop'}</Text>
                  </a>
                </Link>
              </View>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='/pricing'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Pricing'}</Text>
                  </a>
                </Link>
              </View>
            </View>
          </View>
        </View>
        <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLF1]}>
          <View style={styles.footerColumn}>
            <View style={styles.edgeGutter}>
              <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
                {'COMPANY'}
              </Text>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='/contact'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Contact'}</Text>
                  </a>
                </Link>
              </View>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='https://www.workatastartup.com/companies/oneshop'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                    target='_blank'
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Jobs'}</Text>
                  </a>
                </Link>
              </View>
            </View>
          </View>
          <View style={styles.footerColumn}>
            <View style={styles.edgeGutter}>
              <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
                {'RESOURCES'}
              </Text>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='/policies'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>
                      {'Marketplace Policies'}
                    </Text>
                  </a>
                </Link>
              </View>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='/blog'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Blog'}</Text>
                  </a>
                </Link>
              </View>
            </View>
          </View>
        </View>
        <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLF1]}>
          <View style={styles.footerColumn}>
            <View style={styles.edgeGutter}>
              <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
                {'SOCIAL'}
              </Text>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='https://www.youtube.com/channel/UC7wKt6XZoagcp8Ry71d4ZWA'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                    target='_blank'
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'YouTube'}</Text>
                  </a>
                </Link>
              </View>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='https://www.facebook.com/oneshopcompany'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                    target='_blank'
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Facebook'}</Text>
                  </a>
                </Link>
              </View>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='https://www.instagram.com/oneshopcom/'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                    target='_blank'
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Instagram'}</Text>
                  </a>
                </Link>
              </View>
            </View>
          </View>
          <View style={styles.footerColumn}>
            <View style={styles.edgeGutter}>
              <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
                {'LEGAL'}
              </Text>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='/legal/privacy'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                    target='_blank'
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Privacy'}</Text>
                  </a>
                </Link>
              </View>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='/legal/terms'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                    target='_blank'
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Terms'}</Text>
                  </a>
                </Link>
              </View>
              <View style={Constants.GridStyle.MBUnit}>
                <Link href='/legal/cancellation'>
                  <a
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Cancellations'}</Text>
                  </a>
                </Link>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.disclaimerColumn}>
        <View style={styles.edgeGutter}>
          <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
            {'© 2024 Inventory Systems, Inc.'}
          </Text>
          <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
            {
              'List Perfectly is a trademark of ListPerfectly, Inc. Vendoo is a trademark of Vendoo, Inc. Poshmark is a trademark of Poshmark, Inc. Mercari is a trademark of Mercari, Inc. Tradesy is a trademark of Tradesy, Inc. Depop is a trademark of Depop Ltd. Etsy is a trademark of Etsy, Inc. Kidizen is a trademark of Kidizen, Inc. Grailed is a trademark of Grailed, Inc. Heroine is a trademark of Grailed, Inc. eBay is a trademark of eBay, Inc. Facebook is a trademark of Facebook, Inc. Facebook Marketplace is a trademark of Facebook, Inc. OneShop is not endorsed, certified, or affiliated by List Perfectly, Vendoo, mercariBOT, ResellKit, SellerInsight, PrimeLister, Cross List It, Hammoq, ListingJoy, Crosslist, Poshmark, Tradesy, Mercari, Depop, Grailed, Facebook Marketplace, eBay, Kidizen, or Etsy. The names List Perfectly, Vendoo, Crosslist, Poshmark, Tradesy, Mercari, Depop, Grailed, Facebook Marketplace, eBay, Kidizen, and Etsy, as well as related names, marks, emblems and images are registered trademarks of their respective owners.'
            }
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    paddingTop: 4 * Constants.Grid.dp(36),
  },
  navLogo: {
    maxWidth: Constants.Grid.dp(100),
  },
  footerColumn: {
    flex: 1,
    marginBottom: Constants.Grid.dp(24),
  },
  contentColumn: {
    width: '100%',
    maxWidth: Constants.Grid.dp(960),
  },
  disclaimerColumn: {
    width: '100%',
    maxWidth: Constants.Grid.dp(960),
  },
  edgeGutter: {
    paddingHorizontal: Constants.Grid.dp(12),
  },
  heroImage: {
    width: '100%',
  },
  featurePreview: {
    padding: Constants.Grid.dp(24),
    borderRadius: Constants.Grid.dp(6),
    backgroundColor: Constants.NewColor.White,
    zIndex: 1,
  },
  heroButton: {
    height: Constants.Grid.dp(48),
    borderRadius: Constants.Grid.dp(24),
  },
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default Footer;
